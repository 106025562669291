import axios, { Method } from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const request = (
  method: Method,
  url: string,
  options = { params: {}, payload: {}, headers: {} }
) => {
  const request = {
    url,
    method,
    params: options.params,
    data: options.payload,
    headers: {
      "Content-Type": "application/json",
      ...options.headers,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .request(request)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

/* REQUEST INTERCEPTOR */

axios.interceptors.request.use(async (request) => {
  const headers: any = {
    // apikey: process.env.REACT_APP_API_KEY,
  };
  const token = await localStorage.getItem("authToken");

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  request.headers = headers;

  return request;
});

/* RESPONSE INTERCEPTOR */

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.response;
  }
);

export const GET = (path: string, params: any) => {
  return request("GET", path, {
    params,
    payload: {} as any,
    headers: {} as any,
  });
};

export const POST = (path: string, payload: any) => {
  return request("POST", path, {
    payload,
    params: {} as any,
    headers: {} as any,
  });
};

export const PUT = (path: string, payload: any) => {
  return request("PUT", path, {
    payload,
    params: {} as any,
    headers: {} as any,
  });
};

export const DELETE = (path: string, params: any = {} as any) => {
  return request("DELETE", path, {
    params,
    payload: {} as any,
    headers: {} as any,
  });
};

export const POSTFormData = (path: string, payload: any) => {
  return request("POST", path, {
    payload,
    params: {} as any,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const PUTFormData = (path: string, payload: any) => {
  return request("PUT", path, {
    payload,
    params: {} as any,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
