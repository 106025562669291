import React from "react";
import Layout from "../../components/layout";
import { Link } from "react-router-dom";

import styles from "./NotFoundPage.module.scss";
import { route } from "../../static/route";

const NotFoundPage = () => {
  return (
    <Layout>
      <div className={styles.NotFoundPage}>
        <div className="logoSection">
          <img src="/logo.png" className="m-auto lg:m-0 w-[140px]" alt="" />
        </div>

        <div className="mt-[70px] px-10 py-5 text-center">
          <p className="mt-4 text-primary-2 font-bold text-2xl">
            Page Not Found (404)
          </p>
          <p className="mt-4 text-primary-2 font-semibold text-lg">
            Sorry, the page you are looking for is not available on our website.
            The URL you entered might be incorrect or the page may have been
            removed.
          </p>
          <p className="mt-4 text-primary-2 font-semibold text-lg">
            Go to the homepage: To view the main page of our website <br />
            <Link to={route.home}>
              <a className="text-xl font-bold underline">Click here.</a>
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default NotFoundPage;
