import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import loggingUserSlice from "../features/loggingUser/loggingUserSlice";
import settingsSlice from "../features/settings/settingsSlice";

export const store = configureStore({
  reducer: {
    loggingUser: loggingUserSlice,
    settings: settingsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
