import React from "react";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { route } from "./static/route";
import LoginPage from "./pages/login";
import NotFoundPage from "./pages/notFound";
import DashboardPage from "./pages/dashboard";
import PrivateRoute from "./components/route/PrivateRoute";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path={route.login} element={<LoginPage />} />

        <Route
          path={route.dashboard}
          element={
            <PrivateRoute element={<DashboardPage />} path={route.login} />
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthProvider>
  );
};
export default App;
